@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 400;
	src:
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmb2Rj.woff2 */
		url('../fonts/Roboto_Slab_400.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmb2Rl.woff */
		url('../fonts/Roboto_Slab_400.woff') format('woff');
}
@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 100;
	src:
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWmb2Rj.woff2 */
		url('../fonts/Roboto_Slab_100.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWmb2Rl.woff */
		url('../fonts/Roboto_Slab_100.woff') format('woff');
}
@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 300;
	src:
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSmb2Rj.woff2 */
		url('../fonts/Roboto_Slab_300.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSmb2Rl.woff */
		url('../fonts/Roboto_Slab_300.woff') format('woff');
}
@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 700;
	src:
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4Omb2Rj.woff2 */
		url('../fonts/Roboto_Slab_700.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4Omb2Rl.woff */
		url('../fonts/Roboto_Slab_700.woff') format('woff');
}
