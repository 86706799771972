@font-face {
	font-family: 'Droid Serif';
	font-style: normal;
	font-weight: 400;
	src:
		local('Droid Serif Regular'),
		local('DroidSerif-Regular'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbI2oqRg1oM3QBjjcaDkOr9rAU.woff2 */
		url('../fonts/Droid_Serif_400.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbI2oqRg1oM3QBjjcaDkOr9rAM.woff */
		url('../fonts/Droid_Serif_400.woff') format('woff');
}
@font-face {
	font-family: 'Droid Serif';
	font-style: normal;
	font-weight: 700;
	src:
		local('Droid Serif Bold'),
		local('DroidSerif-Bold'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbV2oqRg1oM3QBjjcaDkOJGiRD7OwE.woff2 */
		url('../fonts/Droid_Serif_700.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbV2oqRg1oM3QBjjcaDkOJGiRD7Owc.woff */
		url('../fonts/Droid_Serif_700.woff') format('woff');
}
@font-face {
	font-family: 'Droid Serif';
	font-style: italic;
	font-weight: 400;
	src:
		local('Droid Serif Italic'),
		local('DroidSerif-Italic'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbK2oqRg1oM3QBjjcaDkOr4nAfcHg.woff2 */
		url('../fonts/Droid_Serif_400italic.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbK2oqRg1oM3QBjjcaDkOr4nAfcGA.woff */
		url('../fonts/Droid_Serif_400italic.woff') format('woff');
}
@font-face {
	font-family: 'Droid Serif';
	font-style: italic;
	font-weight: 700;
	src:
		local('Droid Serif Bold Italic'),
		local('DroidSerif-BoldItalic'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbX2oqRg1oM3QBjjcaDkOr4lLz5CwOnSA.woff2 */
		url('../fonts/Droid_Serif_700italic.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/droidserif/v12/tDbX2oqRg1oM3QBjjcaDkOr4lLz5CwOnTg.woff */
		url('../fonts/Droid_Serif_700italic.woff') format('woff');
}
