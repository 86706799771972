@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src:
		local('Montserrat Regular'),
		local('Montserrat-Regular'),
		/* from https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2 */
		url('../fonts/Montserrat_400.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhzQ.woff */
		url('../fonts/Montserrat_400.woff') format('woff');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src:
		local('Montserrat Bold'),
		local('Montserrat-Bold'),
		/* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2 */
		url('../fonts/Montserrat_700.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD-A.woff */
		url('../fonts/Montserrat_700.woff') format('woff');
}
